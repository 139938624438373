//import ProjectSugestions from "../Components/ProjectSugestions"
import ProjectTitle from "../Components/ProjectTitle";

const SmartHomeProject = () => {
  const id = "smarthome"
  const title="SmartHome system with automatic garage door"

  return (
    <div className="page" id={id}>
      <section id="introduction">
        <ProjectTitle title={title} />

        <img src="https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthouse2.jpg" alt="Alps" />

        <p>
          SmartHome project uses a remote server to handle both home and user
          client requests, front-end UI to interact with the IoT features, and
          an automatic garage door mechanism incharge of identifing the
          proximity of a vehicle and authenticate this one.
        </p>

        <h2>Project Context</h2>
        <p>
          This project was a final assignment for my <i>System Fundamentals</i>{" "}
          core class as an undergraduate for <i>Fall 2019</i>. All the students
          were faced with the challenge of creating a project that implemented
          most if not all the concepts learned throughout the semester. The
          smart IoT home project idea was created as a solution to satisfy the
          requirements but mainly with the ambition to think big. The core idea
          for the project not only covered all chapters during the class but
          also was an opportunity to dig dipper and be curious eventually making
          possible combining different technologies such as IoT, mechatronics,
          automation, wireless protocol design, hardware programming,
          networking, and others in a single objective to innovate homes, and
          possible inspire new products such as gadgets for Smart Homes in a
          huge market such as real state or home improvement.
        </p>
      </section>
      <section id="demostration">
        <h2>Demostration Video</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/RzZGgW7jKYA"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={id}
          ></iframe>
        </div>

        <p>
          In order to overcome some initial challegens such as: the amount of
          tasks done by the Raspberry Pi simultaniously combined with the
          Infrared or {"(IR)"} sensor, the used of multiple ultrasonic sensors
          and to deliver exceptional results, additional research than required
          for the course was needed to assembly all different features in a
          working prototype. Some of the concepts were outside the scope of the
          class but made the project development a very hands on creative, an
          opportunity to explore and learn outside the teaching material, and a
          great source of experience for future projects. In the process, I
          became familiar with concepts such as: multi-thread and multi-core in
          python using Raspberry, server architecture and client authentication,
          stepper motors and stepper motors programming, infrared light-emitting
          diode {"IR LED"}, IR protocols such as Infrared Transmission Protocol
          and IR sensors, Arduino programming, and others which are explained in
          more detail in the <a href="#background">Background</a> section.
        </p>
      </section>
      <section id="materials">
        <h2>Materials</h2>
        <ul>
          <li>Raspberry Pi</li>
          <li>Arduino nano</li>
          <li>SD card with Raspbian operating system</li>
          <li>Breadboard</li>
          <li>Pi ‘Wedge’ breadboard adapter</li>
          <li>Ribbon cable to connect the Wedge to the Pi</li>
          <li>Power supplies for the Pi and monitor</li>
          <li>4 LEDs</li>
          <li>Two Resistors (220 ohm)</li>
          <li>Breadboard M-M wires for interconnects</li>
          <li>Customized 3D printed parts</li>
          <li>IR transmitter and Receiver modules</li>
          <li>2 Ultrasonic Sensors</li>
          <li>one stepper motor28BYJ-48 and ULN2003 Driver</li>
          <li>
            Linear Actuator 3d printed model can be found{" "}
            <a rel="noopener" href="https://www.thingiverse.com/thing:3798066">
              here
            </a>
          </li>
        </ul>
      </section>
      <section id="background">
        <h2>Background</h2>

        <p>
          The project counts with four main concepts in order to manage the
          logic, security and functionality of the system which are:{" "}
          <a href="#background">Flask Server and browser client</a>,{" "}
          <a href="#homeclient">the SmartHome client and vehicle</a>,{" "}
          <a href="#irlistener">the IR listener</a>, and{" "}
          <a href="#garagedoor">the Garage door</a>.
        </p>

        <ul>
          <li>
            <h3 id="applicationui">Flask Server and Browser client: </h3>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthouse_ui_dashboard.png"
              alt="smarthouse dashboard UI"
            />
            <p>
              The UI for the project is a dashboard using plain JavaScript and
              plain custom CSS for performance and served by a Flask server. The
              server was hosted online to allow connections remote connections
              and not only within the LAN by using a VPS and nginx reverse proxy
              server to deliver HTTPS encrypted connection and redirect
              connections to the upstream Flask server. The webserver offered an
              authentication system with login enabled to first authenticate any
              user before being able to toggle the lights in the SmartHome scale
              model. In addition, to handle the toggle request, the Flask server
              runs in combination of a SQL database using SQLAlchemy toolkit to
              asynchronously save the user request and serve it to the smart
              home client once is connected to the server and ready to execute
              the request.
            </p>
          </li>
          <li>
            <h3 id="homeclient">
              The smart home client and Arduino vehicle aka "the car with no
              wheels":{" "}
            </h3>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthome_client.jpg"
              alt="smarthouse client system"
            />
            <p>
              Most of the research outside the class material was done to make
              possible the use of the Raspberry Pi resources and allocate the
              required processing for the Raspbian OS, handle real-time input
              from peripherals such as sensor reads of an IR sensor and two
              ultranosic sensors, the client connection to the Flask Server
              incharge of getting request stored in the database by the
              browser-client user asyncronously and toggling the lights
              accordingly. In addtion to other processes such as the main
              process which takes care of fully automate the garage door by
              calculating the data from the sensors, identifying and
              authenticating the precense of the "vehicle", and making decisons
              to active the steppers motors controlling the actual 3d printed
              door mechanism. There are also another peripherals such as two
              push buttons as a security system for the steppers, which was
              implemented to ensure the stablity and integrity of the system, in
              additon to software like 'debouncing' to deal with issues that
              comes with mechanical hardware such as push buttons bouncing
              signals.
            </p>
            <p>
              In the "Vehicle" side of things, there is an Arduino nano wired up
              on a mini breadboard connecting the IR emitter. The main Arduino
              program, in basic terms, turns the IR LED on and off in a specific
              frequency and patterns of time for the IR receiver in the
              SmartHome. More in detail, the protocol was inspired in the{" "}
              <a
                rel="noopener"
                href="https://techdocs.altium.com/display/FPGA/NEC+Infrared+Transmission+Protocol"
              >
                NEC Infrared Transmission Protocol
              </a>{" "}
              and used first pulse burst of 562.5µs and then a second pulse of
              562.5µs space to represent a logic "0" with a total transmitting
              time 1.125ms, and similarly used a pulse burst of 562.5µs and then
              a second pulse of 1.6875ms space to represent a logic "1" with a
              total transmitting time 2.25ms. The Arduino program would have two
              global variables type{" "}
              <a
                rel="noopener"
                href="https://www.arduino.cc/reference/en/language/variables/data-types/byte/"
              >
                byte
              </a>{" "}
              for address and command, and then decode these using a mask byte
              to select a bit and emit it with the assistance of a function in
              charge of handling the IR LED and which takes two parameters in
              microseconds which are: <b>frequency</b> set to 38000 which was
              found as stable after experimenting with the system, and{" "}
              <b>period</b> of time the IR LED would be pulsing in the given
              frequency{" "}
              {
                "such as 540 (originally 562.5µs from the NEC) to close the protocol, or lead to the first pulse burst of a bit, or 9000µs accompanied with a 4500µs delay to actually indicate the IR sensor the start of the protocol"
              }
              . I have to add, the learning experience from implementing this
              protocol, really taught me the behind the scenes of technology,
              unveiling the "Magic" of any wireless communication such as Wi-Fi.
              In short, I finally understood how my TV remote really works...
            </p>

            <p className="note">
              please note: there is not demo for the car and SmartHome IR since
              it was not recorded back in 2019 but the code for the Arduino
              vehicle IR emitter and the Python IR receiver process to read the
              IR LED will be available in GitHub.
            </p>
          </li>
          <li>
            <h3 id="irlistener">The IR listener: </h3>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthouse_garagedoor2.jpg"
              alt="smarthouse dashboard UI"
            />
            <p>
              an help with the peripheral input of this sensor, there is am IR
              python module that counts with a function{" "}
              <code>{"getIRData(<ir_pin = (default/wired GPIO pin)>)"}</code>{" "}
              which in essentially does all the reading, and ensures that meets
              criteria by detecting: start of the protocol, bust patterns for 0s
              and 1s, closing for the protocol, and only listening on a specific
              frequency. The function runs as indefinitely and consistently
              while the main process is up in a dedicated process. If no errors
              are detected in the protocol while the process is running, it then
              returns a nicely well composed and finished byte which is then
              made available to the main process which along with data collected
              from the Ultrasonic sensors also running in a different process,
              collect information and takes a decision on whether to open or
              close the garage door.
            </p>
          </li>
          <li>
            <h3 id="garagedoor">The Garage door: </h3>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthouse_garagedoor.jpg"
              alt="smarthouse dashboard UI"
            />
            <p>
              As mentioned previously, the program for the Ultrasonic sensors
              run in a separate process within the main program. One of its main
              functions is sending the right sequences to the stepper motor in
              order to open and close the door mechanism using the byte IR
              receives and matching the byte with the secret or reserved byte
              for defining a "Vehicle" allowing the system to differentiate
              another big object from a car. Initially there was an issue found
              with the stepper motors, since it used to run the steppers with an
              estimate for 'x' time at 'y' speed, or simply 'x' steps to
              transition from a state of the door being closed to open and vice
              versa but the inaccuracies that comes as result of working with 3d
              printed pieces such as friction, and gears skipping, and just
              physics of the mechanisms, the system would accumulate errors
              overtime, causing the stepper motors to get stuck while trying to
              open/close the garage door beyond its limits, creating heat and
              excessive power consumption. To solve this issue, another feature
              of this process is to handle the pulse and debouncing of two push
              buttons located at both ends of the gate which are pushed once the
              gate is either fully opened or closed as security mechanisms for
              the stepper motors to determine when the door is actually closed,
              or fully open.
            </p>
          </li>
        </ul>
        {/*<p>
           add a summarize  here, In conclusion...Finally, The automatic garage door system incorporates several of these processes to open and close the garage
          door. The program determines the car position using the distance measured by the ultrasonic sensors
          and acknowledging the "car" presence by reading a known 2 bytes of data being broadcast by the vehicle using the
          IR receiver module connected to the pi. 
        </p>*/}
      </section>
      {/* <ProjectSugestions/> */}
    </div>
  );
}

export default SmartHomeProject
