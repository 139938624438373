import ProjectTitle from "../Components/ProjectTitle";
import { FaGithub } from "react-icons/fa";
const GuessWhoProject = () => {
  const id = "guesswho" // for iframe
  const title = "Guess Who?"
  const GithubUrl = "https://github.com/carlosofscience/GuessWho";

  return (
    <div className="page imgborder" id={id}>
      <section id="introduction">
        <ProjectTitle title={title} GithubUrl={GithubUrl} />
        <p>
          Single-player version of the "Guess Who?" tabletop game as native
          desktop application built in Java & Eclipse IDE.
        </p>
        <h4>Background </h4>
        <p>
          The traditional "Guess Who?" is a game where players take turns asking
          questions to figure out which character their opponent has chosen from
          a set of characters on a board. The goal is to correctly identify the
          character before your opponent does. The game ends when one player
          successfully guesses the other player's character.
        </p>
        <p>
          As a single-player version of the game, the program randomly selects a
          character as the opponent's identity. Now, the goal is not only to
          correctly guess this character but to do so in the least amount of
          guesses possible. The players that don't give up and continue guessing
          until the end of the game will be listed on a scoreboard. The
          objective is to be ranked at the top of this board, rather than just
          winning the game.
        </p>
      </section>
      <section id="features">
        <h2>Features</h2>
        <ul className="features-cards-grid-web">
          <li>
            <div>
              <h4>Login & Game Menu (main)</h4>
              <p>
                The input text field takes the user name and redirects to the
                main menu. The program uses this username to keep track of the
                score and save it to the scoreboard if necessary. The main menu
                includes options to: see the scoreboard, instructions for how to
                play, choose a theme, and start a new game.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/login_menu.GIF"
              alt=" Login & Game Menu pages"
            />
          </li>
          <li>
            <div>
              <h4>Play Page</h4>
              <p>
                Displays all the possible characters of the game along with two
                text fields to ask for features and submit these questions. A
                navigation bar with the option to exit the match is at the top
                of the window containing the number of right and wrong guesses
                and a prompt to provide the program's feedback to the user.
              </p>
              <p>
                As the user types in, the fields auto-complete, simplifying the
                process of asking questions regarding the character and making
                it possible to select these player features from a pre-set group
                of characteristics included within the Theme Data.
              </p>
            </div>
            <img src="https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/play_page.GIF" alt="play page" />
          </li>
          <li>
            <div>
              <h4>Identifying character</h4>
              <p>
                While typing in the "Final Guess" input text field, the program
                generates a button with the name matching the current string
                from all possible names within the selected Theme. Once
                submitted, the user is redirected to the confirmation page with
                the character's image and name, and with the option to "discard"
                and continue playing or confirm, only the condition is a one
                time, final guess. Upon choosing confirm, if the user
                incorrectly guesses the character, then the user looses the
                match otherwise the user wins, and the score is listed base on
                the number of guess attempts and sorted has descripted in
                scoreboard page.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/identifying_character.GIF"
              alt="identifying character page"
            />
          </li>
          <li>
            <div>
              <h4>About & Scoreboard</h4>
              <p>
                Slider with 5 latest listings, places for rent, and places for
                sale categories. The navigation located at the bottom of the
                page with options to explore (redirecting to this main page),
                offers page, and profile that redirects to login page if not
                authenticated already.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/about_and_scoreboard.GIF"
              alt="about and scoreboard page"
            />
          </li>
          <li>
            <div>
              <h4>Chosing Theme</h4>
              <p>
                The choosing theme window, allows the user to load a theme from
                the ones available to the game. Each Theme estructure contains a
                imgs directory with all the characters images and a profiles
                text file, containing the character name, image name, and
                features written has questions. These last are read by the
                program, and use to compare user input to auto-generate a
                response to autofill the ask feature and final guess inputs
                matching only possible features and possible names for the
                particular Theme.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/chosing_theme.GIF"
              alt="choosing theme"
            />
          </li>
        </ul>
      </section>
      <section id="links">
        <h2>GitHub Repository Link</h2>
        <ul style={{ listStyle: "none" }}>
          <li key={"github-code"}>
            <a target="_blank" rel="noreferrer" href={GithubUrl}>
              <FaGithub /> <p>GitHub - {title}</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default GuessWhoProject
