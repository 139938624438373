// import ProjectSugestions from "../Components/ProjectSugestions";
import ProjectTitle from "../Components/ProjectTitle";
import { FaGithub } from "react-icons/fa";

const WeatherChromeExtension = () => {
  const id = "WeatherChromeExtension"; // for iframe
  const GithubUrl = 'https://github.com/carlosofscience/weather-extension';
  const downloadLink = 'https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/weather-extension.zip';

  return (
    <div className="page" id={id}>
      <section id="introduction">
        <ProjectTitle
          title={'Weather Chrome Extension'}
          downloadLink={downloadLink}
          GithubUrl={GithubUrl}
        />

        <h2>Overview</h2>

        <p>
          The Chrome Weather extension is a feature-rich weather application
          that displays the current weather conditions for multiple cities. The
          extension integrates with the WeatherAPI to retrieve the latest
          weather data. Users can configure the extension to display the
          temperature in either Celsius or Fahrenheit and can also choose to
          display the weather in an overlay that can be toggled and set to open
          by default on new tabs. Additionally, the extension features a
          temperature badge that displays the current temperature in the browser
          toolbar.
        </p>
      </section>

      <section id="features">
        <h2>Extension Features</h2>
        <ul>
          <li>
            Displays the current weather conditions for any location the user
            may choose.
          </li>
          <li>Consistently updates weather data for the user stored cities</li>
          <li>
            Allows users the flexibility to search for the weather in any
            location format such as zipcode, city name, region and/or state.
          </li>
          <li>
            Allows users to configure the temperature unit (Celsius or
            Fahrenheit) and updates on real time all related components.
          </li>
        </ul>
      </section>
      <section id="usage">
        <h2>Usage</h2>

        <ul className="responsive-features-grid">
          <li className="content-row">
            <div>
              <h4>Search location</h4>
              <p>
                After installing the Chrome Weather extension, click on the
                extension icon in the Chrome toolbar to launch the application.
                The extension fetch the data of any zipcode, city, state or
                region given and display the weather for that location. To
                search for the weather in a different location, click on the "+"
                button and enter the location in the search bar and will be
                added to the list.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/cities.GIF"
              alt="weather alarm"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Home City</h4>
              <p>
                To set a "Home City", right click on the extension weather icon
                at the browser's top right corner and click "options". In the
                options page, there will be a "Home City name" field where you
                can type in the name of the city you want to set as your home
                city. Once you have typed in the name of the city, click on the
                "Save" button to save your settings.
              </p>
              <p>
                After setting your home city, the extension will display the
                weather data for your home city at the top of the popup window,
                on the extension badge in the toolbar and and will also display
                the weather data for your home city in the weather card overlay
                when is active.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/home_city.GIF"
              alt="weather alarm"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Display multiple locations</h4>
              <p>
                When a new city is added by click on the plus icon in the top
                right corner of the extension popup, a weather card is added to
                the popup window containing the weather data. Depending on the
                number of cities added you may have to scroll if necessary. The
                extension can store multiple cities, and users can also remove
                any city by clicking on the "Delete" button in the city card.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/multiple_cities.GIF"
              alt="weather alarm"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Toggle Overlay</h4>
              <p>
                The wather card overlay shows the current weather data for the
                user home city in a weather card displayed on top of any active
                webpage when toggled. In order to display the overlay, this one
                can be toggle at the top right corner of the extension popup
                window, and closed either by clicking the 'delete' button or
                toggle the overlay at the popup.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/toggle_overlay.GIF"
              alt="weather alarm"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Persistent Overlay</h4>
              <p>
                By default, the overlay for the Chrome Weather extension is only
                displayed when it is activated in the extension's popup window.
                However, users can customize this behavior by enabling the "Auto
                toggle overlay on webpage load" option located on the
                extension's Options page. Once enabled, the overlay will be
                automatically displayed on new webpages without the need to
                activate it from the popup window.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/persistent_overlay.GIF"
              alt="weather alarm"
            />
          </li>
        </ul>
      </section>
      <section>
        <h2>Install Instructions</h2>
        <ul>
          <li>
            <a href={downloadLink}>Download</a> the project file.
          </li>
          <li>
            Go to Chrome's extensions Dashboard by typing{' '}
            <b>chrome://extensions</b> in the Browser URL.
          </li>
          <li>
            Ensure <b>Developer mode</b> toggle bar located at the top right
            corner is enabled.
          </li>
          <li>
            Click <b>Load unpacked</b> at the top left corner and select the
            directory for the cloned project.
          </li>
          <li>
            (Optional) Go to the extension icon located at the top right corner,
            and pin the Weather extension so that the icon becomes more
            accessible.
          </li>
          <li>
            After installing the Chrome Weather extension, click on the
            extension icon in the Chrome toolbar to launch the application. By
            default, the extension will display the weather for your current
            location.
          </li>
        </ul>
      </section>
      <section>
        <h2>Future Improvements</h2>
        <p>
          Some potential future improvements that could be made to the Chrome
          Weather extension include:
        </p>
        <ul>
          <li>
            Adding support for more weather data (e.g. wind speed, humidity).
          </li>
          <li>Improving the UI and user experience.</li>
          <li>Adding support for more temperature units (e.g. Kelvin).</li>
          <li>Adding support for more languages.</li>
        </ul>
      </section>

      <section id="links">
        <h2>Repo link</h2>
        <ul style={{ listStyle: 'none' }}>
          <li key={'github-code'}>
            <a target="_blank" rel="noreferrer" href={GithubUrl}>
              <FaGithub /> <p>GitHub - Weather Chrome Extension</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default WeatherChromeExtension;
