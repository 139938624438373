// import ProjectSugestions from "../Components/ProjectSugestions";
import ProjectTitle from "../Components/ProjectTitle";
import { FaCloud, FaGithub } from "react-icons/fa";

const SupportDeskProject = () => {
  const id = "supportdesk"; // for iframe
  const liveDemoUrl = 'https://support-desk-git-vercel-deploy-carlosofscience.vercel.app/'
  const GithubUrl = "https://github.com/carlosofscience/support-desk";

  return (
    <div className="page" id={id}>
      <section id="introduction">
        <ProjectTitle
          title={"Support Desk"}
          liveDemoUrl={liveDemoUrl}
          GithubUrl={GithubUrl}
        />

        <h2>Objective</h2>
        <p>
          Creating a ticketing system both UI and API bringing support to
          services or products as a web application using the MERN stack, and
          React-Redux Toolkit. The project includes:
        </p>
        <ul>
          <li>
            Register, Sign in, Protected routes, Create tickets, View Tickets,
            and create notes for existing tickets.
          </li>
          <li>Protected routes</li>
          <li>
            Auth with JSON Web Tokens (JWT): configured to expire in 30 days and
            use a JWT secret to sign the user id and generate the token.
          </li>
          <li>
            Handling application state management with Redux and Redux toolkit:
            initialState, Actions with createAsyncThunk, Slices with reducers
            extra reducers, useSelector & useDispatch Hooks, and Services
          </li>
        </ul>
      </section>
      <section id="features">
        <h2>Features</h2>
        <ul className="features-cards-grid-web">
          <li>
            <div>
              <h4>Register</h4>
              <p>
                Register form sends a post request to the 'api/users/' route
                that uses the user controller register function containing the
                logic to encrypt the new user-provided password with the help of
                the Bcrypt library that generates a salt to hash the password.
                Creates the user using the create method within the user model
                and storing the user's name, email, and hashed password. returns
                JSON object with user data such as name, email, MongoDB user
                generated id, JWT token generated from this id, and 201 HTTP
                response when the user is successfully registered. Otherwise,
                the controller throws an error for 'invalid user data' along
                with a 400 status.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/register_user.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Sign In</h4>
              <p>
                The Login page submits the form data to 'api/users/login' which
                uses the user controller login function to fetch the user by
                email (if any) and compare the passwords. If the user signs in
                successfully, the request returns a user object containing the
                user id, JWT token, and some user information along with a 200
                HTTP response. Otherwise, the API throws an error for 'invalid
                credentials' along with a 401 status.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/register_user.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Protected routes</h4>
              <p>
                These are routes that need JWT tokens, where user id can be
                obtained. The protect authorization middleware looks for the
                bearer token within the request header and adds the user to the
                request by fetching the user by id obtained after decoding the
                token with the help of the JWT secret. failing any of these
                steps, the middleware adds a 401 status to the response and
                throws a 'Not authorized' error.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/protected_routes.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>View Tickets</h4>
              <p>
                Logged-in users can view a list of their respective tickets at
                /tickets. Each TicketItem component displayed on the list
                displays the date when it was created, product type, closed/open
                status, and a button with more options and details regarding a
                single Ticket. The Ticket page displays the ticket id, date
                submitted, status, product type, issue description, and notes.
                In addition to options such as closing a ticket or adding new
                notes (for open tickets only) On the API side, the ticket
                controller servers the user tickets using those tickets that
                belong to the user ID. Similarly, a single ticket is fetched by
                the ticket id and served under the condition that the user is
                authorized.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/view_tickets.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Ticket Page</h4>
              <p>
                The protected route /new-ticket shows a form displaying the
                current user name and email and options to write a description
                of the issue and select the product type for which support is
                being requested. Upon submission, the form dispatches the
                createTicket function that POST the ticket data and adds the
                bearer token to the request headers reaching the /api/tickets
                protected API endpoint. The backend controller verifies that all
                required fills contain data and user is authorized to return a
                200 HTTP status, and the ticket. Upon the client receiving 200
                status, the app updates the state and redirects to view all
                tickets and notifies the user with a toastify alert.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/create_new_ticket.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Closing Ticket</h4>
              <p>
                This option is displayed on the ticket page. On the client side,
                the close ticket button dispatches the closeTicket service which
                sends a PUT request along with the closed status and the bearer
                token for the ticket id.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/closing_ticket.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Ticket Notes</h4>
              <p>
                Notes can be added to a ticket through the ticket page. If the ticket status is Open then the "add a note" button is displayed. On click, the button opens up a modal with an input text field for the node content, and a "submit" button to save the note. Once created, the note is then displayed on the notes section at the bottom of the page, titled "Note from {"<UserName>"} and date created.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/adding_notes.GIF"
              alt="search page"
            />
          </li>
        </ul>
      </section>
      <section id="links">
        <h2>Repo & Live demo links</h2>
        <ul style={{ listStyle: "none" }}>
          <li key={"live-demo"}>
            <a target="_blank" rel="noreferrer" href={liveDemoUrl}>
              <FaCloud /> <p>Support Desk - (live demo)</p>
            </a>
          </li>
          <li key={"github-code"}>
            <a target="_blank" rel="noreferrer" href={GithubUrl}>
              <FaGithub /> <p>GitHub - Support Desk</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default SupportDeskProject;
