import { FaCloud, FaGithub } from "react-icons/fa";
import ProjectTitle from "../Components/ProjectTitle";
const FeedbackAppProject = () => {

  return (
    <div className="page" id="feedbackapp_page">
      <section id="introduction">
        <ProjectTitle
          title={"Feedback UI"}
          liveDemoUrl={"https://spiffy-babka-8a688c.netlify.app/"}
          GithubUrl={"https://github.com/carlosofscience/feedback-app"}
        />
        <p>
          React project to write reviews and gather feedback regarding products
          and services.
        </p>

        <h2>Objective</h2>
        <p>
          Contribute to the development of full-stack projects through learning
          and practicing React concepts such as:
        </p>
        <ul>
          <li>Components, Props, State, Forms, Routes, Links, Icons.</li>
          <li>Context API, useContext hook</li>
          <li>
            APIs, HTTP Requests, and deployment using a variety of hostings.
          </li>
        </ul>
        <p>
          In addition, the application uses react v8 and works with
          react-router-dom v6 new changes such as the replacement of Switch with
          Routes, between other major changes.
        </p>
      </section>
      <section id="features">
        <h2>Features</h2>
        <ul className="features-cards-grid">
          <li className="card">
            <div>
              <h4>Create Review</h4>
              <p>
                Allows to create reviews with a rating selection ranging from 1
                to 10, and at least 10 characters long.
              </p>
            </div>
            <img src="https://s3.amazonaws.com/sandval.com/imgs/projects/FeedbackUIapp/create_review.GIF" alt="" />
          </li>
          <li className="card">
            <div>
              <h4>Update Review</h4>
              <p>
                The edit button located at the top right corner of existing
                reviews, fills up the form and allows the update upon clicking
                "Send"{" "}
              </p>
            </div>
            <img src="https://s3.amazonaws.com/sandval.com/imgs/projects/FeedbackUIapp/update_review.GIF" alt="" />
          </li>
          <li className="card">
            <div>
              <h4>Delete Review</h4>
              <p>
                The delete button found on the top right corner of existing
                reviews, prompts with "Are you sure?" message before deletion
              </p>
            </div>
            <img src="https://s3.amazonaws.com/sandval.com/imgs/projects/FeedbackUIapp/delete_review.GIF" alt="" />
          </li>
          <li className="card">
            <div>
              <h4>Calculate reviews, and rating average</h4>
              <p>
                The values for number of reviews are updated on the event of
                creating, deleting. In addtion, the average is calculated on
                this conditrions as well as the update reviews when changing the
                rating value{" "}
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/FeedbackUIapp/update_reviews_and_rating_av.GIF"
              alt=""
            />
          </li>
        </ul>
      </section>
      <section id="links">
        <h2>Repo & Live demo links</h2>
        <ul style={{ listStyle: "none" }}>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://spiffy-babka-8a688c.netlify.app/"
            >
              <FaCloud /> <p>Feedback UI - (live demo)</p>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://feedback-app-sy4n.onrender.com/feedback"
            >
              <FaCloud /> <p>JSON Server API (live demo)</p>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/carlosofscience/feedback-app/tree/feedback-app-jserver-render"
            >
              <FaGithub /> <p>Github - JSON Server API</p>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/carlosofscience/feedback-app"
            >
              <FaGithub /> <p>Github - Feedback UI</p>
            </a>
          </li>
        </ul>
      </section>
      {/* <ProjectSugestions /> */}
    </div>
  );
}

export default FeedbackAppProject
