import './App.css';
import AppContainer from './Components/AppContainer';
import { AppProvider } from './AppContext';


function App() {

  return (
        <AppProvider>
          <AppContainer/>
        </AppProvider>
  );
}

export default App;
