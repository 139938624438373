import ProjectCard from "./ProjectCard"

const ProjectSugestions = () => {
  return (
     <section id="suggested-posts">
        <h1>More Projects</h1>
        <div className="projectcardscontainer">
          <ProjectCard name="Guess Who" img={{source:"https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/guesswho.png", alt:"guesswho board"}} tags={['Java', 'OOP', 'Design Patterns']} destination={'/projects/guesswho'} />
        </div>
      </section>
  )
}

export default ProjectSugestions
