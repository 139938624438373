import {Link} from 'react-router-dom'
import Button from './Button'
import { AppContext } from '../AppContext';
import { useContext } from 'react';

import { FaGithub, FaCode, FaLinkedin } from 'react-icons/fa'
import {BiMailSend} from 'react-icons/bi'

const Navigation = ({className}) => {
  const setShowSiderbar = useContext(AppContext)[1]

  return (
    <ul className={`options ${className}`}>
      <li key={"1p"} onClick={() => setShowSiderbar(false)}>
        <Link to="/projects">
          <Button key={"1p"} text={["Projects"]}>
            <FaCode />
          </Button>
        </Link>
      </li>
      <li key={"2g"} onClick={() => setShowSiderbar(false)}>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://github.com/carlosofscience"
        >
          <Button text={["GitHub"]}>
            <FaGithub />
          </Button>
        </a>
      </li>
      <li key={"3c"} onClick={() => setShowSiderbar(false)}>
        <Link to="/contact">
          {" "}
          <Button text={[" Contact"]}>
            <BiMailSend />
          </Button>{" "}
        </Link>
      </li>
      <li key={"4l"} onClick={() => setShowSiderbar(false)}>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://linkedin.com/in/sandval"
        >
          <Button text={["LinkedIn"]}>
            <FaLinkedin />
          </Button>
        </a>
      </li>
    </ul>
  );
}

export default Navigation
