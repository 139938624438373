import ProjectCard from './ProjectCard'
const ProjectsPage = () => {
  return (
    <div className="page" id="projects_page">
      <h1>Projects</h1>
      <hr />

      <div className="projectcardscontainer">
        {' '}
        <ProjectCard
          key="project-card-weather"
          name={['Weather Extension']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/weatherChromeExtension/weatherextension.png',
            alt: 'Weather Extension',
          }}
          tags={['Weather Extension']}
          destination={'/projects/weatherchromeextension'}
        />
        <ProjectCard
          key="project-card-pomodoro"
          name={['Pomodoro Extension']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/pomodoroChromeExtension/pomodoro.PNG',
            alt: 'Pomodoro timer',
          }}
          tags={['Chome Extension']}
          destination={'/projects/pomodorochromeextension'}
        />
        <ProjectCard
          key="project-card-houseMarketplace"
          name={['House Marketplace']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/houseMarketplace.png',
            alt: 'House Marketplace',
          }}
          tags={['React 18.2.0', 'Vercel', 'Firebase']}
          destination={'/projects/housemarketplace'}
        />
        <ProjectCard
          key="project-card-FeedbackUIApp"
          name={['Feedback App']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/FeedbackUIapp/FeedbackUIApp.png',
            alt: 'Feedback App',
          }}
          tags={['React 18.2.0', 'Heroku', 'Netlify']}
          destination={'/projects/feedbackapp'}
        />
        <ProjectCard
          key="project-card-supportdesk"
          name={['Support Desk System']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/supportdesk/supportdesk.png',
            alt: 'Support Desk',
          }}
          tags={['React 18.2.0', 'Railway', 'MERN']}
          destination={'/projects/supportdesk'}
        />
        <ProjectCard
          key="project-card-githubfinder"
          name={['Github Finder']}
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/githubfinder_profile.png',
            alt: 'Github Finder',
          }}
          tags={['React 18.2.0', 'Vercel']}
          destination={'/projects/githubfinder'}
        />
        <ProjectCard
          key="project-card-smarthome"
          name="Smart IoT Home System"
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/smarthome/smarthouse_garagedoor.jpg',
            alt: 'scale model of smart home',
          }}
          tags={['C programming', 'tutorial']}
          destination={'/projects/smarthome'}
        />
        <ProjectCard
          key="project-card-guesswho"
          name="Guess Who"
          img={{
            source:
              'https://s3.amazonaws.com/sandval.com/imgs/projects/guesswho/guesswho.png',
            alt: 'guesswho board',
          }}
          tags={['Java', 'OOP', 'Design Patterns']}
          destination={'/projects/guesswho'}
        />
        {/* <ProjectCard key="project-card-basicblog" name={["Basic Blog"]} img={{source:"https://s3.amazonaws.com/sandval.com/imgs/projects/basicblog/basicblog.png", alt:"blog dashboard"}} tags={['Ruby on Rail', 'PostgreSQL', 'MVC']} destination={'/projects/basicblog'} />
        <ProjectCard key="project-card-developerdataanalyticstool" name="Analytics Tool for Dev stats " img={{source:"https://s3.amazonaws.com/sandval.com/imgs/projects/developerdataanalyticstool/dataanalytics.png", alt:"bar graph"}} tags={['Python', 'Data Structures']} destination={'/projects/developerdataanalyticstool'} /> */}
      </div>
    </div>
  );
}

export default ProjectsPage
