import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <p>Copyright © 2023 All rights reserved</p>
        <ul>
          <li key={"f-contact"}>
            <a href="/contact">
              <BiMailSend />
            </a>
          </li>
          <li key={"f-github"}>
            <a href="https://github.com/carlosofscience">
              <FaGithub />
            </a>
          </li>
          <li key={"f-linkedin"}>
            <a href="https://www.linkedin.com/in/sandval/">
              <FaLinkedin />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
