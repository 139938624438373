import { useState } from "react";

const ContactPage = () => {

  const email = "carlosofscience@gmail.com"
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  return (
    <div className="page" id="contact_page">
      <h1>Contact</h1>
      <hr />
      <form className="contactForm">
        <label htmlFor="textsubject">Title</label>
        <input type="text" name="subject" id="textsubject" value={subject} onChange={e=>{setSubject(e.target.value)}} />
        <label htmlFor="textbody">Message</label>
        <textarea cols="30" rows="10" id="textbody" value={body} onChange={e=>{setBody(e.target.value)}} ></textarea>
        <a href={`mailto:${email}?Subject=${subject}&body=${body}`}>
          <button type="button">
            Send Message
          </button>
        </a>
      </form>
    </div>
  );
}

export default ContactPage
