import React, { useState, createContext} from "react";


export const AppContext = createContext();

export const AppProvider = props =>{
  const [showSidebar, setShowSidebar] = useState(false);

   return (
    <AppContext.Provider value={[showSidebar, setShowSidebar]}>
      {props.children}
    </AppContext.Provider>
  );
}