// import ProjectSugestions from "../Components/ProjectSugestions"
import { FaCloud, FaGithub } from "react-icons/fa";
import ProjectTitle from "../Components/ProjectTitle";
const GithubFinderProject = () => {
  return (
    <div className="page" id="githubfinder_page">
      <section id="introduction">
        <ProjectTitle
          title={"GitHub Finder"}
          liveDemoUrl={"https://github-finder-ashen-nine.vercel.app/"}
          GithubUrl={"https://github.com/carlosofscience/github-finder"}
        />
        <p>
          React Application using GitHub API to search users and display profile
          data including respositories and other user details.
        </p>

        <h2>Objective</h2>
        <p>
          Working with 3rd party APIs, create a single page web application
          which UI shows GitHub users' information, and work with React
          teachnologies such as:
        </p>
        <ul>
          <li>Context API, Redcurers, and useReducer Hook</li>
          <li>
            Libraries/packages: Daisy UI, Tailwind CSS, React Icons & Router.
          </li>
          <li>
            Search and display GitHub users: image, name, username, location,
            website, twitter, GitHub followers, GitHub following, public
            repositories & public Gists
          </li>
          <li>APIs, HTTP Requests, and deployment using Vercel.</li>
          <li>Responsive Design</li>
        </ul>
      </section>
      <section id="features">
        <h2>Features</h2>
        <ul className="features-cards-grid-web">
          <li>
            <div>
              <h4>Search Page (main)</h4>
              <p>
                Home page for the application, contains a navigation bar with
                app Name, Home and About page links, Footer and main body with a
                search box to type the user's name.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/search_page.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Details about User Page (main)</h4>
              <p>
                The User page contains GitHub user's image, name & username,
                intro, and details fetched from the API regarding their profile,
                gists, GitHub repos, and links to user's website and social media
                (if any).
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/user_page.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Search box Clear and error</h4>
              <p>
                Home page search box dosplays an error when from is submited but
                no letters have been typed, in addition, a clear button appears
                upon results to clear the page.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/error_and_clear.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>Navigation & About page</h4>
              <p>
                The apps uses React Router to navigate between pages. The about
                pages displays the App name, version and credits the author of
                the React Course that inspired this project.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/error_and_clear.GIF"
              alt="search page"
            />
          </li>
          <li>
            <div>
              <h4>About GitHub API</h4>
              <p>
                Different GitHub API endpoints have a different number of
                requests that can be made during a specific amount of time or
                rate limits. API GitHub tokens are personal tokens that require
                an account, but when used the rate limit is increased. As of
                today, the difference between the limit for fetching user
                profile info wihtout and with token is from 10 to 30 requests
                per minute.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/githubfinder/postman.png"
              alt="search page"
            />
          </li>
        </ul>
      </section>
      <section id="links">
        <h2>Repo & Live demo links</h2>
        <ul style={{ listStyle: "none" }}>
          <li key={"live-demo"}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github-finder-ashen-nine.vercel.app/"
            >
              <FaCloud /> <p>GitHub Finder - (live demo)</p>
            </a>
          </li>
          <li key={"github-code"}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/carlosofscience/github-finder"
            >
              <FaGithub /> <p>GitHub - GitHub Finder</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default GithubFinderProject;
