
import { FaCloud, FaGithub } from "react-icons/fa";
import ProjectTitle from "../Components/ProjectTitle";
const HouseMarketplace = () => {
  const title = "House Marketplace";
  const GithubUrl = "https://github.com/carlosofscience/house-marketplace";
  const liveDemoUrl = "https://house-marketplace-nu-pearl-86.vercel.app/";

  return (
    <div className="page" id="housemarketplace_page">
      <section id="introduction">
        <ProjectTitle
          title={title}
          liveDemoUrl={liveDemoUrl}
          GithubUrl={GithubUrl}
        />
        <p>
          Full stack application using React to create real estate property
          listings for both rent and sale, in addition to special offers at a
          discounted price.
        </p>
        <h4>Main features:</h4>
        <ul>
          <li>List properties both Homes or Apartments</li>
          <li>List properties under rent and/or for sale</li>
          <li>Create discounts promoted as special offers</li>
          <li>
            Create, edit or delete listings with up to 6 images, and seller
            contact information
          </li>
          <li>Responsive Design</li>
        </ul>
      </section>
      <section id="features">
        <h2>Features</h2>
        <ul className="features-cards-grid-web">
          <li>
            <div>
              <h4>Explore Page (main)</h4>
              <p>
                Slider with 5 latest listings, places for rent, and places for
                sale categories. The navigation located at the bottom of the
                page with options to explore (redirecting to this main page),
                offers page, and profile that redirects to login page if not
                authenticated already.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/explore_slide.GIF"
              alt="explore page"
            />
          </li>
          <li>
            <div>
              <h4>Login and Register </h4>
              <p>
                The App uses firebase Database and Authentication providers:
                native email/password, and an additional provider such as
                Google. In combination with Firebase{" "}
                <span title="Commmon Expression Language">CEL</span> rules to
                set permissions like allowing anyone to 'read' public listings
                but authorized users to create, and only the ones with the right
                permission to edit, and so. The login and register also offer
                options to set new passwords where a link is sent to the user's
                email.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/login_and_register.GIF"
              alt="auth pages"
            />
          </li>
          <li>
            <div>
              <h4>User Profile Dashboard</h4>
              <p>
                Contains options to log out, create listings, and sections such
                as the personal details section that displays the user name and
                email with the option to update the username, and the user
                listing section that shows the options for editing and deleting
                the listings created by the user.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/update_username.GIF"
              alt="user profile page"
            />
          </li>
          <li>
            <div>
              <h4>Create, edit and delete listings </h4>
              <p>
                The form shows two modes for Sell and Rent. Depending on which
                of these modes is being selected, the form updates fields
                specific to the form type such as adding to the right listing
                category and specifying whether the price is monthly or
                full-price for a 1-time deal. Other fields remain the same as
                they are needed for both listings. The edit page follows the
                same concept and is identical to the Create Listing page, only
                that this one, does not create a new listing but instead updates
                the current one with the existing listing ID. In both cases, if
                everything goes right, the application redirects to the listing
                page displaying all details for the particular listing.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/delete_create_listing.GIF"
              alt="create listing page"
            />
          </li>
          <li>
            <div>
              <h4>Display Property Listing </h4>
              <p>
                Listings show a slide with a maximum of 6 images, a share icon,
                property name, description, and address, as well as a map
                pointing to the given longitude and latitude of the property
                address (explain how this works). An option to contact the
                landlord is only shown if the listing does not belong to the
                current user.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/listing.GIF"
              alt="property listing"
            />
          </li>
          <li>
            <div>
              <h4>Listing Categories: Rent & Sale </h4>
              <p>
                The places for rent and sale page show the listings for each
                category.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/sale_and_rent_categories.GIF"
              alt="rent and sale page"
            />
          </li>
          <li>
            <div>
              <h4>Special Offers </h4>
              <p>
                The special offer page shows every listing with discounted
                price.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/HouseMarketplace/special_offfers.GIF"
              alt=""
            />
          </li>
        </ul>
      </section>
      <section id="links">
        <h2>Repo & Live demo links</h2>
        <ul style={{ listStyle: "none" }}>
          <li key={"live-demo"}>
            <a
              target="_blank"
              rel="noreferrer"
              href={liveDemoUrl}
            >
              <FaCloud /> <p>House Marketplace - (live demo)</p>
            </a>
          </li>
          <li key={"github-code"}>
            <a target="_blank" rel="noreferrer" href={GithubUrl}>
              <FaGithub /> <p>GitHub - House Marketplace</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default HouseMarketplace;
