
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../AppContext';

import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import ContactPage from '../Components/ContactPage';
import ProjectsPage from '../Components/ProjectsPage';
import Footer from './Footer';

import ProjectExample from '../Projects/ProjectExample'
import SmartHomeProject from '../Projects/SmartHomeProject';
import GuessWhoProject from '../Projects/GuessWhoProject';
import DeveloperDataAnalyticsToolProject from '../Projects/DeveloperDataAnalyticsToolProject';
import BasicBlogProject from '../Projects/BasicBlogProject';
import FeedbackAppProject from '../Projects/FeedbackAppProject'
import GithubFinderProject from '../Projects/GithubFinderProject';
import HouseMarketplace from '../Projects/HouseMarketplace'
import SupportDeskProject from "../Projects/SupportDeskProject";
import PomodoroChromeExtension from "../Projects/PomodoroChromeExtension";
import WeatherChromeExtension from '../Projects/WeatherChromeExtension'

function AppContainer() {

  const [showSidebar, setShowSidebar] = useContext(AppContext);
  return (

      <Router>
        <div className="App">
          <Header showSidebar={()=>setShowSidebar(true)}/>
          <main className='mainbody'>
            {console.log(showSidebar)}

          <CSSTransition
            in={showSidebar}
            timeout={300}
            classNames="sidebar" unmountOnExit>

            <Sidebar/>
          </CSSTransition>
            <div className="wrapper page_container">
              <Routes className="wrapper page_container">
                {/* website pages  */}
                <Route path="/" element={<ProjectsPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/projects" element={<ProjectsPage/>}/>
                <Route path="/home" element={<ProjectsPage/>}/>
                {/* projects */}
                <Route path="/projects/projectexample" element={<ProjectExample/>}/>
                <Route path="/projects/smarthome" element={<SmartHomeProject/>}/>
                <Route path="/projects/guesswho" element={<GuessWhoProject/>}/>
                <Route path="/projects/developerdataanalyticstool" element={<DeveloperDataAnalyticsToolProject/>}/>
                <Route path="/projects/basicblog" element={<BasicBlogProject/>}/>
                <Route path="/projects/feedbackapp" element={<FeedbackAppProject/>}/>
                <Route path="/projects/githubfinder" element={<GithubFinderProject/>}/>
                <Route path="/projects/housemarketplace" element={<HouseMarketplace/>}/>
                <Route path="/projects/pomodorochromeextension" element={<PomodoroChromeExtension/>}/>
                <Route path="/projects/weatherchromeextension" element={<WeatherChromeExtension/>}/>
                <Route path="/projects/supportdesk" element={<SupportDeskProject/>}/>
              </Routes>
            </div>
          </main>
          <Footer/>
        </div>
      </Router>
  );
}

export default AppContainer;
