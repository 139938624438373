import ProjectSugestions from "../Components/ProjectSugestions"

const BasicBlogProject = () => {
  const id = "guesswho" // for iframe
  return (
    <div className="page" id={id}>
      <h1>Basic Blog - Ruby on rails blog</h1>
      <p>
        A blog for project is currently unavailable, but instructions on how to replicate, and deploy to GitHub are detailed in the <a rel="noopener" href="https://github.com/carlosofscience/basic-blog">GitHub repo</a>. There is a live view available hosted using Heroku of the current project which is available @<a rel="noopener" href="https://nameless-chamber-19865.herokuapp.com/">Basic Blog</a>. Please note: you will be prompt for credentials for any actions other than comments such as create, edit or delete posts. Please feel free to <a rel="noopener" href="mailto:contact@sandovalcarlos.com">contact me</a> if you would like to test it while I work on creating a demo-video.
      </p>   
      
      <ProjectSugestions/>
    </div>
  )
}

export default BasicBlogProject
