import Navigation from "./Navigation"


const Header = ({showSidebar}) => {
  return (
    <header>
      <div className="wrapper">
        <h1>Carlos Sandoval - Projects {"&"} Porfolio</h1>
        <Navigation className="header-navigation"/>
        <span className="open-sidebar-btn btn" onClick={()=>showSidebar(true)}>🥪</span>
      </div>
    </header>
  )
}

export default Header
