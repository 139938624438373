import { FaCloud, FaPlus, FaCode, FaCloudDownloadAlt } from 'react-icons/fa';
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function ProjectTitle({ title, liveDemoUrl, GithubUrl, downloadLink, removeProjectUrl}) {

  const [isSticky, setIsSticky] = useState(false);
  const boxRef = useRef();
  const [x, setX] = useState();

  useEffect(() => {
    const getPosition = () => {
      const x = boxRef?.current?.offsetLeft;
      setX(x);
    };

    const handleScroll = () => {
      if (window.scrollY >= 159) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
        getPosition()
      }
    };

    getPosition()

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", getPosition);

    return () => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", getPosition);
    };
    
  }, []);

  return (
    <div className="project_tile">
      <h1>{title}</h1>
      <div
        ref={boxRef}
        style={
          isSticky
            ? { position: "fixed", left: x, top: 30 }
            : { position: "relative" }
        }
      >
        {downloadLink && (
          <a target="_blank" rel="noreferrer" href={downloadLink} title={"Download"}>
            <FaCloudDownloadAlt />
          </a>
        )}
        {liveDemoUrl && (
          <a target="_blank" rel="noreferrer" href={liveDemoUrl} title={"Live Demo"}>
            <FaCloud />
          </a>
        )}
        {GithubUrl && (
          <a target="_blank" rel="noreferrer" href={GithubUrl} title="Code" >
            <FaCode />
          </a>
        )}
        {!removeProjectUrl && (
           <Link to="/projects" title="More Projects">
            <FaPlus />
           </Link>
        )}
      </div>
    </div>
  );
}

export default ProjectTitle;
