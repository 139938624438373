import Navigation from "./Navigation"
import { useContext } from "react"
import { AppContext } from "../AppContext"

const Sidebar = () => {

  const setShowSiderbar = useContext(AppContext)[1]

//use contextapi to save state of sidebar, and links can also close it with using context of displaySidebar
  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <span className="close-sidebar-btn btn" onClick={()=>setShowSiderbar(false)}>✖️</span>
        <h2>Explore more!</h2>
        <Navigation className="sidebar-navigation"/>
      </div>
      <div className="overlay"></div>
    </div>

  )
}

export default Sidebar
