import ProjectSugestions from '../Components/ProjectSugestions'

const DeveloperDataAnalyticsToolProject = () => {
  const id = "developerdataanalyticstool" // for iframe
  return (
    <div className="page" id={id}>
      <h1>Analytics Tool for Developers stats 2020 </h1>
      <p>
        A blog for the project is currently unavailable  meanwhile please check out the project at the <a rel="noopener" href="https://github.com/carlosofscience/developers-stats-analysing-tool">GitHub repo</a>. 
      </p>      
      <ProjectSugestions/>
    </div>
  )
}

export default DeveloperDataAnalyticsToolProject
