
const Button = ({ text, children }) => {
  return (
    <button className={`btn`}>
      <span>
        {children}
        {text}
      </span>
    </button>
  );
};


Button.defaultProps = {
  text:'click me'
}


export default Button
