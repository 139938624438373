import ProjectSugestions from "../Components/ProjectSugestions"

const ProjectExample = () => {
  return (
    <div className="page" id="projectexample_page">

      <h1>Projects Page</h1>

      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni ab porro distinctio fugiat error, maxime iste praesentium debitis cum nam mollitia antium vel!
      </p>
      <ul>
       <li>
        <p>this is a list item</p>
       </li>
      <li>
        <h2>header 2</h2>
        <p>
          paragraph indicating there is a nest list below
        </p>
         <ul>
          <li>
            <p>list item 1 from nested list</p>
          </li>
          <li>
            <p>list item 2 from nested list</p>
          </li>
        </ul>
      </li>
     </ul>
     
      <ProjectSugestions/>
    </div>
  )
}

export default ProjectExample
