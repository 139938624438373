import {Link} from 'react-router-dom'

// import { SiArduino, SiFlask, SiVercel, SiFirebase } from "react-icons/si";
// import { FaReact, FaPython, FaDatabase } from "react-icons/fa";


const ProjectCard = ({img, name, tags, destination}) => {
  return (
   <div id='project_card'>
        <Link to={destination}>
          <div className='project_card'>
            <img src={img.source} alt={img.alt} />
            <div className='project_card_info'>
              <h3>{name}</h3>
              <div className="tags">
                {tags.map((tag, i) => <span key={i}>{tag}</span> )}
              </div>
            </div>
          </div>
        </Link>
    </div>
  )
}

export default ProjectCard
