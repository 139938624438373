// import ProjectSugestions from "../Components/ProjectSugestions";
import ProjectTitle from "../Components/ProjectTitle";
import { FaGithub } from "react-icons/fa";

const PomodoroChromeExtension = () => {
  const id = "PomodoroChromeExtension"; // for iframe
  const GithubUrl = "https://github.com/carlosofscience/promodoro-chrome-extension";
  const downloadLink = "https://s3.amazonaws.com/sandval.com/imgs/projects/pomodoroChromeExtension/pomodoro-timer.zip"

  return (
    <div className="page" id={id}>
      <section id="introduction">
        <ProjectTitle
          title={'Pomodoro Chrome Extension'}
          downloadLink={downloadLink}
          GithubUrl={GithubUrl}
        />

        <h2>Description</h2>
        <p>
          The Pomodoro Timer is a Chrome extension which includes: a main popup
          page to interface with the pomodoro timer, allows to set the Pomodoro
          session through a options page, a background script to handle the
          timer logic in addtion to keep track to the TODO task/s that are being
          completed in the session. Technologies involved for the extension are:
          Javascript, CSS, HTML, Chrome API for storage, alarms and
          notifications.
        </p>
        <h2>Background</h2>
        <p>
          The Pomodoro technique consist of simply choosing a task to work on,
          then setting a timer for 25 minutes, and working on the task until the
          timer goes off. Later on, a short break of 5-10 minutes is taken
          before starting another Pomodoro. After four Pomodoros, a longer break
          of 15-30 minutes is required.
        </p>
      </section>

      <section id="features">
        <h2>Extension Features</h2>
        <ul className="responsive-features-grid">
          <li className="content-row">
            <div>
              <h4>Keeping track of TODOs</h4>
              <p>
                The popup reminds the user of what the goals are by allowing to
                add all tasks to be completed to the current Pomodoro session.
                These tasks are store using Chrome store API to persist the data
                until this one is clear by the user or the extension is removed.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/pomodoroChromeExtension/addTask.gif"
              alt="pomodoro task"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Timer Options</h4>
              <p>
                The default countdown time is set to 25 minutes according to
                pomodoros standards, but users also have the flexibility to set
                their own time for personalized purposes. The extension comes
                with a options page where the user can set the default timeout
                value to the start the Pomodoro countdown timer.{' '}
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/pomodoroChromeExtension/options.gif"
              alt="pomodoro Options"
            />
          </li>
          <li className="content-row">
            <div>
              <h4>Timer Alarms & Notificaitions</h4>
              <p>
                Once the timer reaches finishes at 00:00, a notification popsup
                informing that 'x' minutes have passed. At any moment, the user
                can restart the cauntdown to the set or default time value while
                the timer is stopped or not.
              </p>
            </div>
            <img
              src="https://s3.amazonaws.com/sandval.com/imgs/projects/pomodoroChromeExtension/alarms.gif"
              alt="pomodoro alarm"
            />
          </li>
        </ul>
      </section>

      <section>
        <h2>Install Instructions</h2>
        <ul>
          <li>
            <a href={downloadLink}>Download</a> the project file.
          </li>
          <li>
            Go to Chrome's extensions Dashboard by typing{' '}
            <b>chrome://extensions</b> in the Browser URL.
          </li>
          <li>
            Ensure <b>Developer mode</b> toggle bar located at the top right
            corner is enabled.
          </li>
          <li>
            Click <b>Load unpacked</b> at the top left corner and select the
            directory for the cloned project.
          </li>
          <li>
            (Optional) Go to the Extension icon located at the top right corner,
            and pin the Pomodoro Timer so that the icon becomes more accessible.
          </li>
        </ul>
      </section>

      <section id="links">
        <h2>Repo link</h2>
        <ul style={{ listStyle: 'none' }}>
          <li key={'github-code'}>
            <a target="_blank" rel="noreferrer" href={GithubUrl}>
              <FaGithub /> <p>GitHub - Pomodoro Chrome Extension</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PomodoroChromeExtension;
